import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { faAdd, faArrowsRotate, faSave } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paper } from "@mui/material";
import { YYYY_MM_DD } from "@relatable/helpers/date";
import { getUserLabel } from "@relatable/helpers/user";
import { Button } from "@relatable/ui/Button";
import { DateInput } from "@relatable/ui/DateInput";
import { Select } from "@relatable/ui/Select";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";
import { useNavigate, useParams } from "react-router-dom";

import { Prompt } from "components/Prompt";
import { UploadInput } from "components/ui/UploadInput";
import { useSignUploadUrlMutation } from "modules/generated";

import { faLink } from "@fortawesome/pro-solid-svg-icons";
import {
  type CampaignExpenseDependenciesQuery,
  CampaignExpenseDetailsDocument,
  type CampaignExpenseDetailsQuery,
  CampaignExpensesDocument,
  useUpsertExpenseMutation
} from "./generated";

const Section = styled(Paper)`
  border-radius: 15px !important;
  padding: 20px !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;

const SubSection = styled.div`
  display: flex;
  gap: 10px;
`;

export const ExpenseEditorFields: FC<{
  details: CampaignExpenseDetailsQuery["campaign_expense_by_pk"];
  campaignUsers: CampaignExpenseDependenciesQuery["campaign_users"];
  campaignId?: number;
}> = ({ details, campaignUsers, campaignId }) => {
  const { campaignStub } = useParams<{ campaignStub: string }>();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [receiptSaved, setReceiptSaved] = useState(true);

  const [signUploadUrlMutation] = useSignUploadUrlMutation();

  const [fields, setFields] = useState({
    date: details?.date ?? YYYY_MM_DD(new Date()),
    description: details?.description ?? "",
    amount: details?.amount ?? 0,
    vat: details?.vat ?? 0,
    campaign_user_id: details?.campaign_user?.id ?? 0,
    receipt: details?.receipt,
    banking_details: details?.banking_details || ""
  });

  const getError = (values: Partial<CampaignExpenseDetailsQuery["campaign_expense_by_pk"]>) => {
    if (!values) return "Invalid parameter";

    const mandatoryFields: (keyof typeof fields)[] = [
      "description",
      "date",
      "campaign_user_id",
      "amount"
    ];

    for (const field of mandatoryFields) {
      if (field in values && !values[field]) return `${field} is mandatory`;
    }

    if (typeof values?.amount === "number" && values.amount < 0) {
      return "Amount cannot be negative.";
    }

    if (typeof values?.vat === "number" && values.vat < 0) {
      return "VAT cannot be negative.";
    }
    return null;
  };

  const handleFieldChange = (values: Partial<typeof fields>) => {
    if (!values) return;

    setFields(prev => ({
      ...prev,
      ...values
    }));
  };

  const [upsertExpense, { loading }] = useUpsertExpenseMutation({
    onError(err) {
      snackbar.error(err.message);
    },
    onCompleted() {
      setReceiptSaved(true);
    },
    refetchQueries: [CampaignExpenseDetailsDocument, CampaignExpensesDocument],
    awaitRefetchQueries: true
  });

  const handleFileChange = async (files: File[]) => {
    const file = files[0];
    if (!file) return;

    try {
      const { data } = await signUploadUrlMutation({
        variables: {
          input: { fileType: file.type, prefix: "EXPENSE_RECEIPT", fileName: file.name }
        }
      });

      if (!data?.signUploadUrl) {
        snackbar.error("Something went wrong when signing a file for the upload");
      } else {
        const { signedUploadUrl, signedDownloadUrl } = data.signUploadUrl;
        const response = await fetch(signedUploadUrl, {
          method: "PUT",
          body: file,
          headers: { "content-type": file?.type }
        });

        if (!response.ok) {
          snackbar.error("Something went wrong when uploading a file");
        } else {
          handleFieldChange({ receipt: signedDownloadUrl });
          setReceiptSaved(false);
        }
      }
    } catch {
      snackbar.error("Something went wrong when uploading a file");
    }
  };

  const handleUpsert = async () => {
    const error = getError(fields);
    if (error) {
      snackbar.error(error);
      return;
    }

    await upsertExpense({
      variables: {
        set: {
          id: details?.id ?? undefined,
          campaign_id: campaignId,
          amount: fields.amount,
          date: fields.date,
          description: fields.description,
          vat: fields.vat,
          campaign_user_id: fields.campaign_user_id,
          receipt: fields.receipt,
          banking_details: fields.banking_details
        }
      },
      onCompleted: () => {
        snackbar.success("The expense was saved");
        // add delay to avoid unsaved receipt dialog
        setTimeout(() => {
          navigate(`/campaign/${campaignStub}/expenses`);
        }, 1000);
      }
    });
  };

  const receipt = fields.receipt || details?.receipt;

  return (
    <>
      <Prompt
        shouldPrompt={!receiptSaved}
        promptMessage="You did not save the receipt, do you want to leave?"
      />

      <Section elevation={2}>
        <h3>
          {details ? (
            <span>Editing expense &quot;{details.description}&quot;</span>
          ) : (
            <span>Adding a new expense</span>
          )}
        </h3>

        <SubSection>
          <DateInput
            label="Date the expense was made"
            value={new Date(fields.date ?? new Date().toISOString()).toISOString().split("T")[0]}
            onChange={v => handleFieldChange({ date: v })}
            style={{ width: 220 }}
          />

          <Select
            hideNone
            label="Participant creator"
            value={fields.campaign_user_id ? String(fields.campaign_user_id) : null}
            style={{ width: 220 }}
            options={(campaignUsers ?? []).map(campaignUser => ({
              label: getUserLabel(campaignUser.user),
              value: String(campaignUser?.id ?? 0)
            }))}
            onChange={campaignUserId =>
              handleFieldChange({ campaign_user_id: Number(campaignUserId) })
            }
          />

          <TextInput
            required
            label="Description"
            value={fields.description ?? ""}
            onChange={v => handleFieldChange({ description: v })}
            style={{ flexGrow: 1 }}
          />
        </SubSection>
      </Section>

      <Section elevation={2}>
        <h3>Amount</h3>
        <SubSection>
          <TextInput
            label="Currency"
            value={
              campaignUsers.find(u => u.id === fields.campaign_user_id)?.currency ||
              "Choose a creator first"
            }
            style={{ marginBottom: 20, width: "33%" }}
            disabled
          />

          <TextInput
            required
            type="number"
            label="Amount including VAT (if any)"
            value={String(fields.amount ?? 0)}
            style={{ width: "33%" }}
            onChange={v => handleFieldChange({ amount: Number(v) })}
          />

          <TextInput
            required
            type="number"
            label="VAT (put 0 if no VAT)"
            value={String(fields.vat ?? 0)}
            style={{ width: "33%" }}
            onChange={v => handleFieldChange({ vat: Number(v) })}
          />
        </SubSection>
      </Section>

      <Section elevation={2}>
        <h3>Upload</h3>

        {!details?.receipt && (
          <p style={{ margin: 0 }}>
            For participant reimbursements, we need the receipt. Don&apos;t have the receipt? Check
            with the finance team what to do.
          </p>
        )}

        <div style={{ display: "flex", gap: 10 }}>
          <UploadInput
            variant="outlined"
            style={{ width: 200 }}
            onChange={handleFileChange}
            icon={<FontAwesomeIcon icon={receipt ? faArrowsRotate : faAdd} />}
            label={receipt ? "Replace receipt" : "Add receipt"}
          />

          {receipt && (
            <Button
              variant="outlined"
              icon={<FontAwesomeIcon icon={faLink} />}
              href={receipt}
              target="_blank"
            >
              Show receipt
            </Button>
          )}
        </div>
      </Section>

      <Section elevation={2}>
        <TextInput
          label="Banking notes"
          multiline
          value={fields.banking_details}
          onChange={v => handleFieldChange({ banking_details: v })}
        />
      </Section>

      <Button
        icon={<FontAwesomeIcon icon={faSave} />}
        style={{ margin: "auto", display: "flex", minWidth: 200 }}
        isLoading={loading}
        onClick={handleUpsert}
      >
        Save
      </Button>
    </>
  );
};
