import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { faCaretDown, faCaretRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { unique } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";

import { Headline } from "components/ui/Headline";
import { LinearProgress } from "components/ui/LinearProgress";
import { SectionContainer } from "lib/styled";
import { useVerticalsQuery } from "modules/generated";
import type { OverviewQuery } from "modules/overview/generated";
import type { InfluencerMarketingBudget } from "src/typings/global";

import { getPlatformLabel } from "@relatable/gql";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import { objectEntries, objectKeys } from "@relatable/helpers/objects";
import { Accordion } from "@relatable/ui/Accordion";
import { CampaignRow } from "./CampaignRow/CampaignRow";
import { ProjectToolbar } from "./ProjectToolbar/ProjectToolbar";

type ProjectRowProps = {
  agreement_url?: string;
  archived: boolean;
  campaignsAggregate: OverviewQuery["clients"][number]["projects"][number]["campaigns_aggregate"]["aggregate"];
  influencerMarketingBudget: InfluencerMarketingBudget;
  name: string;
  partialCampaigns: Array<
    OverviewQuery["clients"][number]["projects"][number]["campaigns_aggregate"]["nodes"][number]
  >;
  participantsReach: number;
  participantsCount: number;
  payoutBudgetUsed: number;
  legacyTimeTracking: OverviewQuery["clients"][number]["projects"][number]["project_time_tracking"];
  totalHours: number;
  project_team_members: OverviewQuery["clients"][number]["projects"][number]["project_team_members"];
  proposal_url?: string;
  stub: string;
  target_reach?: number;
  target_views?: number;
  defaultOpen?: boolean;
};

export const ProjectRow: React.FC<ProjectRowProps> = ({
  agreement_url,
  archived,
  campaignsAggregate,
  influencerMarketingBudget,
  name,
  partialCampaigns = [],
  participantsReach = 0,
  participantsCount,
  payoutBudgetUsed,
  legacyTimeTracking,
  totalHours,
  project_team_members = [],
  proposal_url,
  stub,
  target_reach = 0,
  target_views = 0,
  defaultOpen = false
}) => {
  const [open, setOpen] = useState(defaultOpen);

  const verticalsQuery = useVerticalsQuery();
  const isArchived = archived === true;

  const groupedCampaigns = partialCampaigns.reduce<
    Partial<Record<Platform_Enum, typeof partialCampaigns>>
  >((acc, campaign) => {
    if (!campaign.platform) throw Error("Missing platform");
    const current = acc[campaign.platform];
    return {
      ...acc,
      [campaign.platform]: current ? [...current, campaign] : [campaign]
    };
  }, {});

  const platforms = objectKeys(groupedCampaigns);

  return (
    <StyledSectionContainer>
      <Root isArchived={isArchived ? "true" : undefined}>
        <ExpandProjectIconButton onClick={() => setOpen(!open)}>
          <FontAwesomeIcon icon={open ? faCaretDown : faCaretRight} size="lg" />
        </ExpandProjectIconButton>
        <Headline variant="h6" className="margin-left-right">
          {name}
        </Headline>
        <span style={{ fontSize: 12, marginRight: "auto" }}>
          {unique(
            partialCampaigns.flatMap(c => {
              if (typeof c.vertical_ids === "string") throw new Error(c.vertical_ids);
              return c.vertical_ids.map(verticalId => {
                if (!verticalsQuery.data) return "";
                const item = verticalsQuery.data.vertical.find(v => v.id === verticalId);
                if (!item) throw Error(`vertical not found: ${c.vertical_ids}`);
                return item.name;
              });
            })
          ).join(" • ")}
        </span>
        {isArchived ? <ArchivedIndicator>Archived</ArchivedIndicator> : null}
      </Root>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {open && (
          <>
            <ProjectToolbar
              agreement_url={agreement_url}
              influencerMarketingBudget={influencerMarketingBudget}
              legacyTimeTracking={legacyTimeTracking}
              totalHours={totalHours}
              partialCampaigns={partialCampaigns}
              project_team_members={project_team_members}
              proposal_url={proposal_url}
              stub={stub}
              target_reach={target_reach}
              target_views={target_views}
            />
            <ProjectAggregateMetricsContainer>
              <LinearProgress
                label="Reach"
                value={participantsReach}
                max={campaignsAggregate?.sum?.target_reach || 0}
              />

              <LinearProgress
                label="Participants"
                value={participantsCount}
                max={campaignsAggregate?.sum?.target_participants || 0}
              />

              <LinearProgress
                label="Budget"
                value={payoutBudgetUsed}
                max={campaignsAggregate?.sum?.max_budget || 0}
              />
            </ProjectAggregateMetricsContainer>

            {platforms.length > 1 ? (
              <Accordion
                options={objectEntries(groupedCampaigns).map(([key, values]) => ({
                  title: getPlatformLabel(key),
                  content: (
                    <CampaignTable
                      campaigns={values || []}
                      influencerMarketingBudget={influencerMarketingBudget}
                    />
                  )
                }))}
              />
            ) : (
              <CampaignTable
                campaigns={partialCampaigns}
                influencerMarketingBudget={influencerMarketingBudget}
              />
            )}
          </>
        )}
      </Collapse>
    </StyledSectionContainer>
  );
};

const CampaignTable: FC<{
  campaigns: ProjectRowProps["partialCampaigns"];
  influencerMarketingBudget: ProjectRowProps["influencerMarketingBudget"];
}> = ({ campaigns, influencerMarketingBudget }) => (
  <Table aria-label="collapsible table">
    <StyledTableHead>
      <TableRow>
        <TableCell />
        <TableCell>Campaign name</TableCell>
        <TableCell />
        <TableCell>Progress</TableCell>
        <TableCell>Market(s)</TableCell>
        <TableCell>Vertical(s)</TableCell>
        <TableCell align="right">Payout budget</TableCell>
      </TableRow>
    </StyledTableHead>
    <TableBody>
      {campaigns?.map(c => {
        if (!c) throw Error("Campaign is undefined");
        return (
          <CampaignRow
            key={c.id}
            influencerMarketingBudget={influencerMarketingBudget}
            partialCampaign={c}
          />
        );
      })}
    </TableBody>
  </Table>
);

const Root = styled.div<{ isArchived?: "true" }>`
  display: flex;
  padding: 16px;
  align-items: center;
  font-size: 12px;
  background-color: ${({ isArchived }) => (isArchived ? palette.gray.whiteOff : palette.gray.white)};

  .margin-left-right {
    margin: 0px 16px;
  }
`;

const ArchivedIndicator = styled.span`
  color: ${palette.gray.white};
  background-color: ${palette.gray.black};
  border-radius: 8px;
  padding: 6px 8px;
  margin-right: 36px;
  font-size: 11px;
`;

const ProjectAggregateMetricsContainer = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 30%;
  padding: 10px 20px 30px;
  justify-content: space-between;
`;

const StyledSectionContainer = styled(SectionContainer)`
  && {
    margin: 10px -2px;
    padding: 0px;
  }
`;

const StyledTableHead = styled(TableHead)`
  background-color: ${palette.gray[10]};
  font-weight: 500;
  border-top: 1px solid ${palette.gray[20]};
`;

const ExpandProjectIconButton = styled(IconButton)`
  && {
    height: 1.25em;
    width: 1.25em;
  }
`;
